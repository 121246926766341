import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
export default function ClientFare({ flight }) {
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container" style={{ width: '100%' }}>
                    <p style={{ marginBottom: '0px', width: '100%' }}>Client Fare
                        <span style={{ float: 'right' }}>Currency - {flight?.clientFare && flight?.clientFare[0]?.clientCurrency ? flight?.clientFare[0]?.clientCurrency : "-"}</span>
                    </p>
                </div>
            </div>
            {
                flight?.clientFare && flight?.clientFare?.length > 0 ? (
                    <Table>
                        <thead className="mui-table-header">
                            <BootstrapTooltip1 title="PaxType">
                                <th style={{ textAlign: 'center' }}>PaxType</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Base Fare">
                                <th style={{ textAlign: 'center' }}>Base Fare</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="TaxPP">
                                <th style={{ textAlign: 'center' }}>Tax</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Baggage Fees">
                                <th style={{ textAlign: 'center' }}>Baggage Fees</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Seat">
                                <th style={{ textAlign: 'center' }}>Seat</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Meal">
                                <th style={{ textAlign: 'center' }}>Meal</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="GST">
                                <th style={{ textAlign: 'center' }}>GST</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Client Total (Per Pax)">
                                <th style={{ textAlign: 'center' }}>Client Total  (Per Pax)</th>
                            </BootstrapTooltip1>
                            <BootstrapTooltip1 title="Client Total">
                                <th style={{ textAlign: 'center' }}>Client Total</th>
                            </BootstrapTooltip1>
                        </thead>
                        <tbody>
                            {
                                flight?.clientFare?.sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                        return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                    .map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td align="center">{item?.paxType} * {item?.paxCount}</td>
                                                <td align="center">{(item?.baseFare).toFixed(2)}</td>
                                                <td align="center">{(item?.tax).toFixed(2)}</td>
                                                <td align="center">{(item?.baggagefare).toFixed(2)}</td>
                                                <td align="center">{(item?.seatFare).toFixed(2)}</td>
                                                <td align="center">{(item?.mealFare).toFixed(2)}</td>
                                                <td align="center">{(item?.gst).toFixed(2)}</td>
                                                <td align="center">{(item?.clientTotal).toFixed(2)}</td>
                                                <td align="center">{(item?.clientTotal * item?.paxCount).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })
                            }
                            <tr>
                                <td align='center' className="fw600">
                                    <b>Total</b>
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.baseFare || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.tax || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.baggagefare || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.seatFare || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.mealFare || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + (item?.gst || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + ((item?.clientTotal) || 0), 0)).toFixed(2)}
                                </td>
                                <td align='center' className="fw600">
                                    {(flight?.clientFare?.reduce((total, item) => total + ((item?.clientTotal * item?.paxCount) || 0), 0)).toFixed(2)}
                                    <span className="">{" "}
                                        {flight.netfare > 0 && (
                                            <BootstrapTooltip1
                                                title={
                                                    <>
                                                        <p
                                                            style={{
                                                                borderBottom: "2px solid grey",
                                                            }}
                                                        >
                                                            Fare Details
                                                        </p>

                                                        {flight?.clientMarkup > 0 && (
                                                            <div className="d-flex justify-content-between">
                                                                <div>You Earn</div>
                                                                <div className="ms-1">
                                                                    <CurrencyCodeComponent
                                                                        currencyCode={flight.currency}
                                                                    />

                                                                    {(
                                                                        parseFloat(
                                                                            flight.clientMarkup
                                                                        ) +
                                                                        (flight?.commission > 0
                                                                            ? parseFloat(
                                                                                flight?.commission
                                                                            )
                                                                            : 0)
                                                                    ).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {flight?.clientMarkup > 0 && (
                                                            <div
                                                                className="d-flex justify-content-between"
                                                                style={{
                                                                    fontSize: "10px",
                                                                    color: "lightgray",
                                                                }}
                                                            >
                                                                <div>Agent Mark-up</div>

                                                                <div className="ms-1">
                                                                    <CurrencyCodeComponent
                                                                        currencyCode={flight.currency}
                                                                    />
                                                                    {flight?.clientMarkup?.toFixed(2)}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {flight?.commission > 0 && (
                                                            <div
                                                                className="d-flex justify-content-between"
                                                                style={{
                                                                    fontSize: "10px",
                                                                    color: "lightgray",
                                                                }}
                                                            >
                                                                <div>Commission</div>
                                                                <div className="ms-1">
                                                                    <CurrencyCodeComponent
                                                                        currencyCode={flight.currency}
                                                                    />
                                                                    0.0
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="d-flex justify-content-between"
                                                            style={{
                                                                borderTop: "2px solid grey",
                                                            }}
                                                        >
                                                            <div>Total Amount</div>
                                                            <div className="ms-1">
                                                                <CurrencyCodeComponent
                                                                    currencyCode={flight.currency}
                                                                />
                                                                {flight?.grossFare?.toFixed(2)}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="d-flex justify-content-between"
                                                        // style={{ fontSize: "9px" }}
                                                        >
                                                            <div>Net Fare</div>
                                                            <div className="ms-1">
                                                                <CurrencyCodeComponent
                                                                    currencyCode={flight.currency}
                                                                />
                                                                {flight?.netfare?.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            >
                                                <InfoOutlinedIcon />
                                            </BootstrapTooltip1>
                                        )}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <p style={{ margin: "0px", padding: "0px 10px", width: '40vw', color: 'red' }}>* The fares shown above are for one pax </p>

                    </Table>
                ) : ''
            }

        </div>
    );
}