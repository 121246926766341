import React, { useState, useEffect, useLayoutEffect } from "react";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import "./ViewBooking.css";
import Grid from "@material-ui/core/Grid";
import BusinessIcon from '@mui/icons-material/Business';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import getPaxTotal from "../../functions/getPaxTotal";
import { useHistory } from "react-router-dom";
import {
  checkIfUserStaff,
} from "../../services/AuthService";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import RiseLoading from "../Loader/RiseLoader";
import { loadCreditCardDetails } from "../../services/apiService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BrandedSingleFareTable from "../BrandedFareComponents/BrandedSingleFareTable";
import { Autocomplete } from "@mui/material";
import fopOptions from "../../data/FopOptions";

import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import TextField from "@mui/material/TextField";
import ModalDialog from "@mui/joy/ModalDialog";
import Divider from "@mui/joy/Divider";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { decryptData } from "../../providers/dataEncryptDecryptor";
import { useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Minirules from "../Configuration/Minirules/Minirules";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FlightItinerary from "./FlightItinerary";
import FlightSegmentDetails from "./FlightSegmentDetails";
import PassengerInfo from "./PassengerInfo";
import TablePNR from "./TablePNR";
import SupplierFare from "./SupplierFare";
import ClientFare from "./ClientFare";
import ROE from "./ROE";
import ComissionParting from "./ComissionParting";
import BookingStatusFlow from "./BookingStatusFlow";
import ContactDetails from "./ContactDetails";
import ComissionAndTaxDetails from "./ComissionAndTaxDetails";
import Baggage from "./Baggage";
import { encryptData } from "../../providers/dataEncryptDecryptor";
const cancelDetailObj = {
  orderRefId: "",
  traceId: "",
  createdOn: "",
  bookingSource: "",
  clientName: "",
  clientEmail: "",
  clientPAN: "",
  vendorName: "",
  id: "",
  flight: [
    {
      validatingAirline: "",
      adultCount: "",
      childCount: "",
      infantCount: "",
      currency: "",
      currentStatus: "",
      refundable: "",
      fareType: "",
      segGroups: [
        {
          id: "",
          origin: "",
          destination: "",
          departureOn: "",
          segments: [],
          passengers: [],
        },
      ],
      flightFares: [],
      baggages: [],
      miniRules: [],
      address: {
        addressName: "",
        state: "",
        postalCode: "",
        countryName: "",
        city: "",
      },
      pcc: "",
      commissionDetails: {},
      fop: "",
      isCorporate: "",
      cardInfoId: "",
      flightBookingStatusFlows: [],
      priceClass: "",
    },
  ],
};

export default function ViewBookedTickets() {
  let OrgPccList = [];
  let invoiceRefNo = "";
  const options = ["Booking_Rejected", "Ticketed"];
  const [orderId, setorderId] = useState("");
  const [updateProcess, setupdateProcess] = useState(false);
  const [bookingDetails, setbookingDetails] = useState([]);
  const [cancelDetails, setCancelDetails] = useState(cancelDetailObj);
  const [remarks, setRemarks] = useState([]);
  const [cancelRefno, SetCancelrefno] = useState("");
  const [selectedFullRefund, setSelectedFullRefund] = useState(false);
  const [showTicketPopover, setShowTicketPopover] = useState(null);
  const [showInvoicePopover, setShowInvoicePopover] = useState(null);
  const DownloadTicketOpen = Boolean(showTicketPopover);
  const DownloadInvoiceOpen = Boolean(showInvoicePopover);
  const [updatePNRList, setUpdatePNRList] = useState([]);
  const [status, setStatus] = useState([]);
  const [updateTicketList, setUpdateTicketList] = useState([]);
  const [updatePNRBookingReference, setUpdatePNRBookingReference] =
    useState("");
  const [pccList, setPccList] = useState([]);
  const [getClientName, setClientName] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [errorMsgTkt, seterrorMsgTkt] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [fopText, setFopText] = useState("");
  const [historyDetail, setHistoryDetail] = useState([]);
  const [countDownText, setcountDownText] = useState("");
  const [TicketArray, setTicketArray] = useState([]);
  const [IBossInvoiceURL, setIBossInvoiceURL] = useState("");
  const IBossURL =
    "http://offshore.ibossonline.com/iBoss/PrintHelper/GenerateAtiItinerarayPrintPage.aspx?";
  let additionalServices_BaggageChrgs = 0;
  let additionalServices_mealsChrgs = 0;
  let additionalServices_SeatChrgs = 0;
  const [creditCardList, setCreditCardList] = useState([]);
  const [fop, setFop] = useState("");
  const [selectedcard, setSelectedCard] = useState("");
  const [cancelReqSuccessDialogOpen, setCancelReqSuccessDialogOpen] =
    useState(false);
  const [cancelReqFailureDialogOpen, setCancelReqFailureDialogOpen] =
    useState(false);
  const [cancelRemarks, setCancelRemarks] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [isButtonEnable, setButtonEnable] = useState(false);
  const [showFareChange, setShowFareChange] = useState(false);
  const [isRoundtrip, setIsRoundtrip] = useState(false);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [oldFare, setOldFare] = useState("");
  const [newFare, setNewFare] = useState("");
  const [currentClientCurrencyCode, setcurrentClientCurrencyCode] = useState();
  const [clientID, setClientID] = useState("");
  const [clientProfile, setClientProfile] = useState({});
  const [showFareRule, setShowFareRule] = useState(false);
  const [miniValue, setMiniValue] = useState("Cancellation");
  const [showAddTimeLimit, setShowAddTimeLimit] = useState(false);
  const [tkTimeLimit, setTkTimeLimit] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const history = useHistory();

  // useLayoutEffect(() => {
  //   debugger;
  //   if (performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD) {
  //     debugger;
  //     window.location.reload(); // Force a reload when back button is used
  //   }
  //   if (performance.navigation.type === performance.navigation.TYPE_NAVIGATE) {
  //     debugger;
  //     window.location.reload(); // Force a reload when back button is used
  //   }
  // }, []);
  // useLayoutEffect(() => {
  //   debugger;
  //   const hasPoppedFromViewBooking = localStorage.getItem('PFVB');
  //   // const hasReachedSearchResults = localStorage.getItem('reached-SRFR');
  //   // const hasReachedSearch = localStorage.getItem('reached-SFR');
  //   // const hasReachedfromSearchResults = localStorage.getItem('reached-RFSR');
  //   if (hasPoppedFromViewBooking === 'true') {
  //     alert('You cannot directly access this page. Redirecting to Search...');
  //     localStorage.removeItem('reached-VBFR');
  //     localStorage.removeItem('reached-SRFR');
  //     localStorage.removeItem('reached-SFR');
  //     localStorage.removeItem('reached-RFSR');
  //     localStorage.removeItem('revalState');
  //     localStorage.removeItem('paymentState');
  //     localStorage.removeItem('reValTimer');
  //     localStorage.removeItem('currentTicket');
  //     history.replace('/flight');
  //     window.location.reload();
  //   }
  //   // else if (hasReachedSearchResults === 'true') {
  //   //   alert('You cannot directly access this page. Redirecting to Search Results...');
  //   //   localStorage.removeItem('reached-VBFR');
  //   //   localStorage.removeItem('reached-SRFR');
  //   //   localStorage.removeItem('reached-SFR');
  //   //   localStorage.removeItem('reached-RFSR');
  //   //   localStorage.removeItem('revalState');
  //   //   localStorage.removeItem('paymentState');
  //   //   localStorage.removeItem('reValTimer');
  //   //   localStorage.removeItem('currentTicket');
  //   //   history.replace('/flight/search-results-new');
  //   //   window.location.reload();
  //   // }
  //   // else if (hasReachedSearch === 'true') {
  //   //   localStorage.removeItem('reached-VBFR');
  //   //   localStorage.removeItem('reached-SRFR');
  //   //   localStorage.removeItem('reached-SFR');
  //   //   localStorage.removeItem('reached-RFSR');
  //   //   localStorage.removeItem('revalState');
  //   //   localStorage.removeItem('paymentState');
  //   //   localStorage.removeItem('reValTimer');
  //   //   localStorage.removeItem('currentTicket');
  //   //   alert('You cannot directly access this page. Redirecting to Search...');
  //   //   history.replace('/flight');
  //   //   window.location.reload();
  //   // }
  //   // else if (hasReachedfromSearchResults !== 'true') {
  //   //   localStorage.removeItem('reached-VBFR');
  //   //   localStorage.removeItem('reached-SRFR');
  //   //   localStorage.removeItem('reached-SFR');
  //   //   localStorage.removeItem('reached-RFSR');
  //   //   localStorage.removeItem('revalState');
  //   //   localStorage.removeItem('paymentState');
  //   //   localStorage.removeItem('reValTimer');
  //   //   localStorage.removeItem('currentTicket');
  //   //   alert('You cannot directly access this page. Redirecting to Search...');
  //   //   history.replace('/flight');
  //   //   window.location.reload();
  //   //   return false;
  //   // }
  //   const unblock = history.block((location, action) => {
  //     debugger;
  //     if (action === "POP" && history.location.pathname === "/flight/view-booked-tickets") {
  //       debugger;
  //       alert("Navigation to Previous Page Restricted.");
  //       return false;
  //     }
  //     else if (action === "PUSH" && history.location.pathname === "/flight/view-booked-tickets" && location.pathname !== "/flight/review-booking" && location.pathname !== "/flight/view-booked-tickets") {
  //       debugger;
  //       localStorage.removeItem('reached-VBFR');
  //       localStorage.removeItem('reached-SRFR');
  //       localStorage.removeItem('reached-SFR');
  //       localStorage.removeItem('reached-RFSR');
  //       localStorage.removeItem("paymentState");
  //       localStorage.removeItem("travelers");
  //       localStorage.removeItem("reValTimer");
  //       localStorage.removeItem("currentTicket");
  //       localStorage.removeItem("paymentAttempt");
  //       localStorage.removeItem("revalState");
  //       localStorage.setItem("PFVB", "true");
  //       return true
  //     }

  //     // else {
  //     //   debugger;
  //     //   localStorage.removeItem('reached-VBFR');
  //     //   localStorage.removeItem('reached-SRFR');
  //     //   localStorage.removeItem('reached-SFR');
  //     //   localStorage.removeItem('reached-RFSR');
  //     //   localStorage.removeItem("paymentState");
  //     //   localStorage.removeItem("travelers");
  //     //   localStorage.removeItem("reValTimer");
  //     //   localStorage.removeItem("currentTicket");
  //     //   localStorage.removeItem("paymentAttempt");
  //     //   localStorage.removeItem("revalState");
  //     //   return true;
  //     // }
  //   });
  //   // const unlisten = history.listen((location, action) => {
  //   //   debugger;
  //   //   if (action === 'POP' && location.pathname === "/flight/review-booking") {
  //   //     alert("Navigation to Previous Page Restricted.");
  //   //     history.replace('/flight/review-booking'); // Force stay on the page
  //   //     return false;
  //   //   }
  //   // });

  //   return () => {
  //     debugger;
  //     unblock();
  //   };
  // }, [history]);
  // useEffect(() => {
  //   debugger;
  //   window.history.pushState(null, '', window.location.href);

  //   window.onpopstate = () => {
  //     console.log("page poppin")
  //   };
  // }, []);
  useEffect(() => {
    if (orderId) {
      loadBookingDetails();
      checkIfUserStaff() && loadPCCDetails();
      loadCreditCards();
    }
  }, [orderId]);

  useEffect(() => {
    let temp = sessionStorage.getItem('orderId');
    if (temp) {
      decryptData(temp)
        .then((decryptedData) => {
          setorderId(decryptedData);
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, []);

  useEffect(() => {
    let count = 0;
    cancelDetails?.flight[0]?.segGroups?.map((segGroup) => {
      if (segGroup.isDisabled === false) {
        segGroup?.passengers?.map((passenger) => {
          if (passenger.isChecked) {
            count++;
          }
        });
      }
    });
    setButtonEnable(count > 0 ? true : false);
  }, [cancelDetails]);

  useEffect(() => {
    if (bookingDetails &&
      bookingDetails.flights &&
      bookingDetails.flights.length > 0) {
      const temp = bookingDetails.flights.map((flight) => (flight.flightFares.map((fare) => (
        fare.paxType == "ADT"
          ? getPaxTotal(
            fare,
            bookingDetails.flights[0].adultCount
          ).toFixed(2)
          : fare.paxType == "CHD"
            ? getPaxTotal(
              fare,
              bookingDetails.flights[0].childCount
            ).toFixed(2)
            : fare.paxType == "INF"
              ? getPaxTotal(
                fare,
                bookingDetails.flights[0].infantCount
              ).toFixed(2)
              : 0
      ))))
      setTotalAmount(temp.flat().reduce((acc, value) => acc + parseFloat(value), 0))


      bookingDetails.flights.map((flight) => {
        flight.segGroups.map((segGroup) => {
          setOrigin(segGroup.origin);
          setDestination(segGroup.destination);
          segGroup.segments.map((segment) => {
            if (segment.isReturn) {
              setIsRoundtrip(true);
            }
          });
        });
      });
    }

  }, [bookingDetails])




  async function getClientData() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetClientByGFSId/" +
        orderId
      )
      .then((response) => {
        let clientData = response.data;
        setClientID(clientData.id)
        setClientProfile(clientData)
        generateIBossURL(
          clientData && clientData.iBossBooksCountry
            ? clientData.iBossBooksCountry
            : null
        );

      });
  }
  async function generateIBossURL(iBossCountry) {
    let IBossProfile = null;
    let branchID = null;
    let finyearID = null;
    invoiceRefNo = orderId;
    // await axios
    //   .get(
    //     process.env.REACT_APP_CORE_URL +
    //       "Flights/Booking/GetBooking/v1/GetInvoice/" +
    //       orderId
    //   )
    //   .then((response) => {
    //     console.log(response);
    //     if (response.data) {
    //       invoiceRefNo = response.data.invoiceNo;
    //     }
    //   });
    if (invoiceRefNo) {
      await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/IBossOperations/GetIBossDetails"
        )
        .then((response) => {
          let temp = response && response.data ? response.data : null;
          if (temp) {
            IBossProfile = temp.find(
              (element) => element.iBossBooksCountry === iBossCountry
            );
            branchID =
              IBossProfile && IBossProfile.branchId
                ? IBossProfile.branchId
                : null;
            finyearID =
              IBossProfile && IBossProfile.financialYearId
                ? IBossProfile.financialYearId
                : null;
            if (branchID && finyearID) {
              let text =
                IBossURL +
                "FinYearID=" +
                finyearID +
                "&BranchID=" +
                branchID +
                "&invreference=" +
                invoiceRefNo;
              setIBossInvoiceURL(() => text);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const startTimer = (temp) => {
    //var countDownDate = new Date("2023-05-05T15:43:00").getTime();
    var countDownDate = new Date(temp).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      let tempText =
        days + "d : " + hours + "h : " + minutes + "m : " + seconds + "s";
      setcountDownText(tempText.toString());
      if (distance < 0) {
        clearInterval(x);
        setcountDownText("Expired");
      }
    }, 1000);
  };


  const loadCreditCards = async () => {
    try {
      let result = await loadCreditCardDetails();
      if (result.length) {
        result.map((data) => {
          data.cardinfo.code =
            data.cardinfo.cardType == "American Express"
              ? "AX"
              : data.cardinfo.cardType == "Visa"
                ? "VI"
                : data.cardinfo.cardType == "MasterCard"
                  ? "CA"
                  : data.cardinfo.cardType == "Diners Club"
                    ? "DC"
                    : data.cardinfo.cardType == "Discover"
                      ? "DS"
                      : data.cardinfo.cardType == "JCB"
                        ? "JC"
                        : data.cardinfo.cardType == "Maestro"
                          ? "TO"
                          : data.cardinfo.cardType == "UATP"
                            ? "TP"
                            : "";
        });
        setCreditCardList(result);
      } else {
        setCreditCardList([]);
      }
    } catch (error) { }
  };
  async function loadBookingDetails() {
    setShowLoader(true);

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/NewView/" +
        orderId
      )
      .then((response) => {

        getAddonCal(response.data);
        setTimeout(() => {
          let temp = response.data;
          temp.flights.map((flight) => {
            flight.flightFares = flight.flightFares.sort((a, b) =>
              a.paxType.localeCompare(b.paxType)
            );
          });
          if (checkIfUserStaff()) {
            // console.log(response.data.flights.flightBookingStatusFlows)
            setHistoryDetail(response.data.flights[0].flightBookingStatusFlows);
          }
          getClientData();

          loadCardDetails(temp.flights[0].cardInfoId);
          setbookingDetails(() => temp);
          setUpdatePNRTicketArrayList(temp);

          setTicketArray(temp);
          if (temp.flights[0].tktTimeLimit) { startTimer(new Date(temp.flights[0].tktTimeLimit)) };
          // console.log(" bookingDetails.flights", bookingDetails.flights);
          // setUpdatePNRTicketArrayList(response.data);
          setShowLoader(false);
        }, 1000);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });

  }
  async function loadCancelDetails() {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetCancelDetails/" +
        orderId
      )
      .then((response) => {
        let canDetails = response.data;
        canDetails.flight[0].segGroups = canDetails?.flight[0]?.segGroups?.map(
          (segGroup) => {
            segGroup.isAllChecked = false;
            segGroup.isDisabled = false;
            let count = 0;
            if (segGroup) {
              segGroup.passengers = segGroup.passengers.map((pax) => {
                pax.isDisabled = false;
                pax.isChecked = false;
                pax.isCancelled = false;
                const isAnyPassengerCancelled =
                  pax.flightTicketStatus !== "None" &&
                  pax.flightTicketStatus !== "Ticketed";

                if (isAnyPassengerCancelled) {
                  pax.isDisabled = true;
                  pax.isChecked = true;
                  pax.isCancelled = true;
                }
                pax.isChecked && count++;
                return pax;
              });
              count === segGroup.passengers.length
                ? (segGroup.isAllChecked = true)
                : (segGroup.isAllChecked = false);
              count === segGroup.passengers.length
                ? (segGroup.isDisabled = true)
                : (segGroup.isDisabled = false);
              return segGroup;
            }
          }
        );
        setCancelDetails(canDetails);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            if (
              bookingDetails &&
              bookingDetails.flights &&
              bookingDetails.flights[0]
            ) {
              OrgPccList = response.data.map((item) => {
                return item.pccConfigs;
              });
              if (OrgPccList.length > 0) {
                let tmp = OrgPccList.find(
                  (item) => item.pccCode === bookingDetails?.flights[0]?.pcc
                );
                // if (tmp) {
                //   setGDSInfo(() => tmp.supplierName);
                // } else {
                //   setGDSInfo(() => "");
                // }
              }
            }
            for (var i = 0; i < response.data.length; i++) {
              let k = i;
              if (
                vendorArray.some(
                  (item) => item.id === response.data[k].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            setPccList(tmpArray);
          });
      });
  }



  const fareChangeCheck = async (bookingDetails) => {

    setupdateProcess(true);
    setShowFareRule(false);
    setMiniValue("Cancellation");
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/TicketOrder/v1/FareCheck",
        JSON.stringify({
          orderRefId: bookingDetails ? bookingDetails.orderRefId : "",
          ClientTotalFare: totalAmount
        }),
        {
          headers,
        }
      )
      .then((res) => {
        setupdateProcess(false);

        setOldFare(res.data.oldFare);
        setNewFare(res.data.newFare);
        // setMiniRules()

        if (res.data.oldFare !== res.data.newFare) {
          setShowFareChange(true);
        }
        else {
          setupdateProcess(false);

          setShowFareChange(false);

          updateTicket(bookingDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }





  const updateTicket = async (bookingDetails) => {
    seterrorMsgTkt("");
    setupdateProcess(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/TicketOrder/v1",
        JSON.stringify({
          orderRefId:
            bookingDetails !== undefined ? bookingDetails.orderRefId : "",
        }),
        {
          headers,
        }
      )
      .then(async () => {
        await setupdateProcess(false);
        await loadBookingDetails();
      })
      .catch((err) => {
        setupdateProcess(false);
        seterrorMsgTkt(err.response.data.detail);
        console.log(err);
      });
  };

  // const updateTicket = async (flightDetails) => {

  //   const details = {
  //     PNR: flightDetails.pnr,
  //     Supplier: "",
  //     PCC: "",
  //     TraceId: bookingDetails  ? bookingDetails.traceId : ""
  //   }

  //   try {
  //     // const result = await axios.post(`${process.env.REACT_APP_CORE_URL}/Flights/TicketOrder/v1`,details);
  //   } catch (err) {

  //   }

  // }
  async function loadCardDetails(cardInfoId) {
    if (cardInfoId !== 0) {
      let response = await loadCreditCardDetails();
      response.map((item) => {
        if (item.cardinfo.id === cardInfoId) {
          let string = item.cardinfo.cardNumber.toString();
          //the format should be "AX6017" (CardCode and last 4 digit of the Card Number)
          item.cardinfo.code =
            item.cardinfo.cardType == "American Express"
              ? "AX"
              : item.cardinfo.cardType == "Visa"
                ? "VI"
                : item.cardinfo.cardType == "MasterCard"
                  ? "CA"
                  : item.cardinfo.cardType == "Diners Club"
                    ? "DC"
                    : item.cardinfo.cardType == "Discover"
                      ? "DS"
                      : item.cardinfo.cardType == "JCB"
                        ? "JC"
                        : item.cardinfo.cardType == "Maestro"
                          ? "TO"
                          : item.cardinfo.cardType == "UATP"
                            ? "TP"
                            : "";
          let lastFour = string.substr(string.length - 4);
          setFopText(item.cardinfo.code + lastFour);
          // setPreSelectedCard(item);
          setSelectedCard(item);

          // return (code + lastFour);
        }
      });
    } else {
      return "Cash";
    }
  }


  const getAddonCal = (bookingDetails) => {
    if (
      bookingDetails &&
      bookingDetails.flights &&
      bookingDetails.flights.length > 0
    ) {
      bookingDetails.flights.map(
        (flight) =>
          flight.baggages &&
          flight.baggages.length > 0 &&
          flight.baggages.forEach((item) => {
            additionalServices_BaggageChrgs =
              additionalServices_BaggageChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.meals &&
          flight.meals.length > 0 &&
          flight.meals.forEach((item) => {
            additionalServices_mealsChrgs =
              additionalServices_mealsChrgs + parseFloat(item.amount);
          })
      );
      bookingDetails.flights.map(
        (flight) =>
          flight.seats &&
          flight.seats.length > 0 &&
          flight.seats.forEach((item) => {
            additionalServices_SeatChrgs =
              additionalServices_SeatChrgs + parseFloat(item.amount);
          })
      );
    }
    // setSeatChrgs(additionalServices_SeatChrgs)
    // setExtraBaggageChrgs(additionalServices_BaggageChrgs);
    // setExtraMealsCharge(additionalServices_mealsChrgs);
  };

  const [openPNRDialog, setOpenPNRDialog] = useState(false);

  const handleClickOpenPNRDialog = () => {
    setOpenPNRDialog(true);
  };

  const handleClosePNRDialog = () => {
    seterrorMsg("");
    setOpenPNRDialog(false);
    setUpdatePNRTicketArrayList(TicketArray);
  };

  const setUpdatePNRTicketArrayList = (bookingDetails) => {
    let segment_arrayList = [],
      passengers_arrayList = [];
    bookingDetails.clientName && setClientName(bookingDetails.clientName);
    bookingDetails &&
      bookingDetails.flights &&
      bookingDetails.flights.length > 0 &&
      bookingDetails.flights.map(
        (flight) => (
          flight.segGroups &&
          flight.segGroups.length > 0 &&
          flight.segGroups.map(
            (segGroup) =>
              segGroup.segments &&
              segGroup.segments.length > 0 &&
              segGroup.segments.map((segment) =>
                segment_arrayList.push({
                  origin: segment.origin,
                  destination: segment.destination,
                  departureOn: segment.departureOn,
                  flightNum: segment.flightNum,
                  pnr: segment.pnr,
                })
              )
          ),
          flight.passengers &&
          flight.passengers.length > 0 &&
          flight.passengers
            .sort(function (a, b) {
              if (a.paxType < b.paxType) {
                return -1;
              }
              if (a.paxType > b.paxType) {
                return 1;
              }
              return 0;
            })
            .map((passenger) =>
              passengers_arrayList.push({
                title: passenger.title,
                firstName: passenger.firstName,
                lastName: passenger.lastName,
                paxType: passenger.paxType,
                ticketNumber: passenger.ticketNumber,
              })
            ),
          setFop(
            flight.fop == "" || flight.fop == undefined ? "Cash" : flight.fop
          ),
          // DefaultTicNo(flight.pnr),
          setUpdatePNRBookingReference(flight.pnr ? flight.pnr : "")
        )
      );
    setUpdatePNRList(segment_arrayList);
    setUpdateTicketList(passengers_arrayList);
    //DefaultPnrDetails(segment_arrayList, passengers_arrayList);
  };
  // const DefaultPnrDetails = (segment, passengers) => {
  //   // var x=Object.assign("", updatePNRBookingReference);
  //   // setPnrBookingRef(structuredClone(x))
  //   setPnrDetails(structuredClone(segment));
  //   setTicketList(structuredClone(passengers));
  // };
  // const DefaultTicNo = (tic) => {
  //   setPnrBookingRef(structuredClone(tic));
  // };

  const handleUpdatePNRTicketFieldChange = (value, field, index) => {
    const existingUpdatePNRList = [...updatePNRList];
    const existingUpdateTicketList = [...updateTicketList];

    if (field === "pnr") {
      existingUpdatePNRList[index][field] = value != null ? value : "";
      setUpdatePNRList(existingUpdatePNRList);
    }

    if (field === "ticketNumber") {
      existingUpdateTicketList[index][field] = value != null ? value : "";
      setUpdateTicketList(existingUpdateTicketList);
    }
  };
  const handleCheckboxfullrefundChange = () => {
    setSelectedFullRefund(!selectedFullRefund);
  };

  async function handlePNRTicketNumberUpdate() {
    seterrorMsg("");
    setupdateProcess(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/TicketOrder/v1/UpdateTicket",
        JSON.stringify({
          orderRefId: bookingDetails ? bookingDetails.orderRefId : "",
          traceId: bookingDetails ? bookingDetails.traceId : "",
          pnr: updatePNRBookingReference,
          passengers: updateTicketList,
          segmentInfo: updatePNRList,
          fop: fop,
          status: status,
          remarks: remarks,
          cardinfoid:
            selectedcard != "" && fop == "Credit Card"
              ? selectedcard.cardinfo.id
              : 0,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status == 202) {
          window.location.reload(false);
        } else {
          setupdateProcess(false);
          seterrorMsg(response.data);
        }
      })
      .catch((err) => {
        setupdateProcess(false);
        console.log(err);
        seterrorMsg(err.response.data.detail);
      });
  }
  const updateCancelInvoice = async () => {
    loadCancelDetails();
    setCancelModalOpen(true);
  };
  const updateCancel = async (bookingDetails) => {
    seterrorMsgTkt("");
    setupdateProcess(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/CreatePNR/v1/ReleasePNR",
        JSON.stringify({
          orderRefId: bookingDetails ? bookingDetails.orderRefId : "",
        }),
        {
          headers,
        }
      )
      .then((response) => {
        setupdateProcess(false);
        window.location.reload(false);
      })
      .catch((err) => {
        setupdateProcess(false);
        seterrorMsgTkt(err.response.data.detail);
        console.log(err);
      });
  };

  const getcancellationRequestedRq = () => {

    let cancellationRequestedRq = [];
    let tripType = cancelDetails?.flight[0]?.segGroups.length > 1 ? "Round" : "oneWay"

    cancelDetails?.flight[0]?.segGroups?.map((segGroup, segIndex) => {
      // if (segGroup.isDisabled === false) {
      segGroup?.passengers?.map((passenger) => {
        if (passenger.isChecked && passenger.isCancelled === false) {
          let checkedPassenger = {
            cancelRefId: 0,
            paxId: passenger.id,
            paxName: passenger.firstName + passenger.lastName,
            flightCancellationStatus: "Cancel_Requested",
            services: "Flight",
            segGroupId: segGroup.id,
          };
          let selectedcheckbox = {
            pnr: segGroup.segments[0].pnr || "",
            flightId: segGroup.segments[0].flightNum || "",
            airline: segGroup.segments[0].mrkAirline || "",
            segGroupId: segGroup.id,
            SegType: tripType === "oneWay" ? "oneWay" : segIndex === 0 ? "onward" : "return",
            IsFullRefund: selectedFullRefund,
          };
          cancellationRequestedRq.push({
            ...checkedPassenger,
            ...selectedcheckbox,
          });
        }
      });
      // }
    });
    return cancellationRequestedRq;
  };

  const updateCancelRequestDetails = async () => {
    seterrorMsgTkt("");
    setupdateProcess(true);
    setCancelReqSuccessDialogOpen(false);
    const headers = {
      "Content-Type": "application/json",
    };
    const orderId = bookingDetails.orderRefId.split("GFS")[1] || "";
    let obj = {
      cancelRefNo: null,
      clientId: cancelDetails.id || "",
      orderRefId: bookingDetails.orderRefId || "",
      createdBy: cancelDetails.createdBy || "",
      orderId: orderId || "",
      createdOn: cancelDetails.createdOn || "",
      cancelRequestedDate: null,
      remarks: cancelRemarks,
      cancellationRequestedRq: getcancellationRequestedRq(),
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/CreatePNR/v1/CancelRequest",
        JSON.stringify(obj),
        {
          headers,
        }
      )
      .then((response) => {
        setupdateProcess(false);
        SetCancelrefno(response.data.cancelRefNo);
        setCancelReqSuccessDialogOpen(true);
        // window.location.reload(false);
      })
      .catch((err) => {
        setupdateProcess(false);
        setCancelReqFailureDialogOpen(false);
        setButtonEnable(true)
        console.log(err);
      });
  };

  // async function loadCardDetails(cardInfoId) {
  //   if (cardInfoId !== 0) {
  //     let response = await loadCreditCardDetails();
  //     response.map((item) => {
  //       if (item.cardinfo.id === cardInfoId) {
  //         let string = item.cardinfo.cardNumber.toString();
  //         //the format should be "AX6017" (CardCode and last 4 digit of the Card Number)
  //         let code =
  //           item.cardinfo.cardType == "American Express"
  //             ? "AX"
  //             : item.cardinfo.cardType == "Visa"
  //             ? "VI"
  //             : item.cardinfo.cardType == "MasterCard"
  //             ? "CA"
  //             : item.cardinfo.cardType == "Diners Club"
  //             ? "DC"
  //             : item.cardinfo.cardType == "Discover"
  //             ? "DS"
  //             : item.cardinfo.cardType == "JCB"
  //             ? "JC"
  //             : item.cardinfo.cardType == "Maestro"
  //             ? "TO"
  //             : item.cardinfo.cardType == "UATP"
  //             ? "TP"
  //             : "";
  //         let lastFour = string.substr(string.length - 4);
  //         setFopText(code + lastFour);
  //         // return (code + lastFour);
  //       }
  //     });
  //   } else {
  //     return "Cash";
  //   }
  // }

  // const sortBaggage = (baggage, segGroupArray,type) => {
  //
  //   let onward = segGroupArray[0];
  //   let return = segGroupArray[1];
  //   const sortedBaggageArray = baggage
  //     .map((baggageItem) => {
  //       const { paxType, cityPair } = baggageItem;
  //       const matchingSegment = segGroupArray.find((segment) => {
  //         const { origin, destination } = segment;
  //         return (
  //           paxType === segment.paxType &&
  //           `${origin}${destination}` === cityPair
  //         );
  //       });

  //       return { ...baggageItem, matchingSegment };
  //     })
  //     .sort((a, b) => {
  //       // Sort based on paxType and cityPair
  //       const paxTypeComparison = a.matchingSegment.paxType.localeCompare(
  //         b.matchingSegment.paxType
  //       );
  //       if (paxTypeComparison !== 0) {
  //         return paxTypeComparison;
  //       }

  //       return a.cityPair.localeCompare(b.cityPair);
  //     });
  //   return sortedBaggageArray;
  // };
  const cancelNow = (event) => {
    setButtonEnable(false);
    // setCancelReqFailureDialogOpen(true);
    event.preventDefault();
    updateCancelRequestDetails();
    //setCancelReqSuccessDialogOpen(true);
  };

  const getMiniRulesData = (miniRulesResp, mr_type) => {

    const getMiniRuleRespData = [];
    if (miniRulesResp != undefined) {
      miniRulesResp.forEach(function (item) {
        if (getMiniRuleRespData.indexOf(item) < 0) {
          if (!getMiniRuleRespData.some((obj) => obj === item)) {
            getMiniRuleRespData.push(item);
          }
        }
      });
    }

    const uniqueMiniRuleData = getMiniRuleRespData.filter((value, index) => {
      if (
        value.changeAllowed != false ||
        value.cancelAllowed != false ||
        value.canAmt != 0 ||
        value.exgAmt != 0
      ) {
        if (mr_type == "Cancellation") {
          if (value.cancelAllowed == true) {
            const _value = JSON.stringify(value);
            return (
              index ===
              getMiniRuleRespData.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          }
        } else {
          if (value.changeAllowed == true) {
            const _value = JSON.stringify(value);
            return (
              index ===
              getMiniRuleRespData.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          }
        }
      }
    });


    const removeDuplicateMiniruleInfo = [
      ...new Map(
        uniqueMiniRuleData.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
    return removeDuplicateMiniruleInfo;
  };


  const filterAdultCancellation = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterChildCancellation = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterInfantCancellation = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterAdultChangeDate = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.changeAllowed) {
      return miniRule;
    }
  };
  const filterChildChangeDate = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.changeAllowed) {
      return miniRule;
    }
  };
  const filterInfantChangeDate = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.changeAllowed) {
      return miniRule;
    }
  };

  async function getClients() {

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=id==" +
        clientID
      )
      .then((response) => {

        setcurrentClientCurrencyCode(
          response?.data[0]?.clientpforile.currencyCode
        );
      });
  }

  useEffect(() => {
    if (clientID) {
      getClients()
    }

  }, [clientID])

  const handleFareRuleClick = () => {
    showFareRule ? setShowFareRule(false) : setShowFareRule(true)
  }

  const handleChange = (event, newValue) => {
    setMiniValue(() => newValue);
  };


  const handleCheckboxsegmentChange = (index, value) => {

    let temp = { ...cancelDetails };
    temp.flight[0].segGroups = temp.flight[0].segGroups.map(
      (segGroup, segIndex) => {
        if (segGroup.isDisabled === false) {
          if (index === 1 && segIndex === 1) {
            if (
              value === false &&
              temp.flight[0].segGroups.length > 1 &&
              temp.flight[0].segGroups[1].isAllChecked
            ) {
              temp.flight[0].segGroups[1].isAllChecked = false;
              temp.flight[0].segGroups[0].isAllChecked = false;
              temp.flight[0].segGroups[1].passengers.map((pax) => {
                // !pax.isDisabled && (pax.isChecked = false);
                pax.isCancelled === false && (pax.isDisabled = false);
                pax.isCancelled === false && (pax.isChecked = false);
                return pax;
              });
              temp.flight[0].segGroups[0].passengers.map((pax) => {
                pax.isCancelled === false &&
                  pax.isDisabled === false &&
                  (pax.isChecked = false);
                return pax;
              });
            } else {
              segGroup.passengers.map((pax) => {
                segGroup.isAllChecked = value;
                pax.isCancelled === false &&
                  pax.isDisabled === false &&
                  (pax.isChecked = value);
                return pax;
              });
            }
          } else if (index === 0) {
            segGroup.passengers.map((pax) => {
              segGroup.isAllChecked = value;
              segIndex === 1 && value === true
                ? (segGroup.isDisabled = true)
                : (segGroup.isDisabled = false);
              pax.isCancelled === false && (pax.isChecked = value);
              pax.isCancelled === false && (pax.isDisabled = value);
              return pax;
            });
          }
        } else {
          segGroup.passengers.map((pax) => {
            segGroup.isAllChecked = value;
            index === 0 && segIndex === 1 && value === true
              ? (segGroup.isDisabled = true)
              : (segGroup.isDisabled = false);
            pax.isCancelled === false && (pax.isChecked = value);
            pax.isCancelled === false && (pax.isDisabled = value);
            return pax;
          });
        }
        return segGroup;
      }
    );
    setCancelDetails({ ...cancelDetails, ...temp });
  };


  const handleCheckboxpassengerChange = (segGroupIndex, paxIndex, value) => {

    let temp = { ...cancelDetails };
    let adtCount = 0;
    let infCount = 0;
    let chdCount = 0;
    let selAdtCount = 0;
    let checkedPassCount = 0;

    temp.flight[0].segGroups[segGroupIndex].passengers.forEach((passenger) => {
      const paxType = passenger.paxType;

      const isAnyPassengerCancelled =
        passenger.flightTicketStatus !== "None" &&
        passenger.flightTicketStatus !== "Ticketed";

      if (
        paxType === "ADT" &&
        isAnyPassengerCancelled === false
      ) {
        adtCount += 1;
      } else if (
        paxType === "INF" &&
        isAnyPassengerCancelled === false
      ) {
        infCount += 1;
      } else if (
        paxType === "CHD" &&
        isAnyPassengerCancelled === false
      ) {
        chdCount += 1;
      }
    });

    temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex].isChecked =
      !temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex].isChecked;

    temp.flight[0].segGroups[segGroupIndex].passengers.forEach((passenger) => {
      if (passenger.isChecked) {
        checkedPassCount++;
      }
    });
    if (
      checkedPassCount ===
      temp.flight[0].segGroups[segGroupIndex].passengers.length
    ) {
      if (segGroupIndex === 0) {
        temp.flight[0].segGroups[0].isAllChecked = true;
        if (temp.flight[0].segGroups.length > 1) {
          temp.flight[0].segGroups[1].isAllChecked = true;
          temp.flight[0].segGroups[1].isDisabled = true;
        }
      } else {
        temp.flight[0].segGroups[segGroupIndex].isAllChecked = true;
      }
    } else {
      if (segGroupIndex === 0) {
        temp.flight[0].segGroups[0].isAllChecked = false;
        if (temp.flight[0].segGroups.length > 1) {
          temp.flight[0].segGroups[1].isAllChecked = false;
          temp.flight[0].segGroups[1].isDisabled = false;
        }
      } else {
        temp.flight[0].segGroups[segGroupIndex].isAllChecked = false;
      }
    }

    const tempsegObj = temp.flight[0].segGroups.find(
      (obj) => obj.id !== temp.flight[0].segGroups[segGroupIndex].id
    );
    let index = undefined;
    index = temp.flight[0].segGroups.findIndex((e) => e.id === tempsegObj?.id);

    if (tempsegObj && tempsegObj?.id && index !== undefined) {
      if (temp.flight[0].segGroups[index].isDisabled === false) {
        if (
          temp.flight[0].segGroups.length > 1 &&
          segGroupIndex === 0 &&
          temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex]
            .isCancelled === false
        ) {
          const isCheckedOnward =
            temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex]
              .isChecked;

          temp.flight[0].segGroups[1].passengers[paxIndex].isCancelled ===
            false &&
            (temp.flight[0].segGroups[1].passengers[paxIndex].isChecked =
              isCheckedOnward);
          temp.flight[0].segGroups[1].passengers[paxIndex].isCancelled ===
            false &&
            (temp.flight[0].segGroups[1].passengers[paxIndex].isDisabled =
              isCheckedOnward);
        }
        if (
          segGroupIndex === 1 &&
          temp.flight[0].segGroups[0].isAllChecked &&
          temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex]
            .isCancelled === false
        ) {
          const isCheckedReturn =
            temp.flight[0].segGroups[1].passengers[paxIndex].isChecked;
          temp.flight[0].segGroups[0].passengers[paxIndex].isChecked =
            isCheckedReturn;
        }
        if (
          temp.flight[0].segGroups.length > 1 &&
          adtCount === 1 &&
          infCount > 0
        ) {
          const currentPassenger =
            temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex];
          if (
            currentPassenger.paxType === "ADT" &&
            currentPassenger.isCancelled === false
          ) {
            temp.flight[0].segGroups[segGroupIndex].passengers.forEach(
              (passenger, infantPassengerIndex) => {
                if (passenger.paxType === "INF") {
                  const infantPassenger = temp.flight[0].segGroups[segGroupIndex].passengers[infantPassengerIndex];
                  infantPassenger.isChecked = currentPassenger.isChecked;
                  infantPassenger.isDisabled = currentPassenger.isChecked;
                  temp.flight[0].segGroups[1].passengers[infantPassengerIndex].isChecked = currentPassenger.isChecked;
                  temp.flight[0].segGroups[1].passengers[infantPassengerIndex].isDisabled = currentPassenger.isChecked;
                }
              }
            );
          }
        } else if (adtCount > 1 && infCount > 0) {
          const currentPassenger =
            temp.flight[0].segGroups[segGroupIndex].passengers[paxIndex];
          if (
            currentPassenger.paxType === "ADT" &&
            currentPassenger.isCancelled === false
          ) {
            temp.flight[0].segGroups[segGroupIndex].passengers.forEach(
              (passenger) => {
                passenger.paxType === "ADT" &&
                  passenger.isChecked &&
                  selAdtCount++;
              }
            );
            if (
              temp.flight[0].segGroups.length > 1 &&
              adtCount === selAdtCount
            ) {
              temp.flight[0].segGroups[segGroupIndex].passengers.forEach(
                (passenger, infantPassengerIndex) => {
                  if (
                    temp.flight[0].segGroups.length > 1 &&
                    passenger.paxType === "INF"
                  ) {
                    const infantPassenger =
                      temp.flight[0].segGroups[segGroupIndex].passengers[
                      infantPassengerIndex
                      ];
                    infantPassenger.isChecked = currentPassenger.isChecked;
                    temp.flight[0].segGroups[1].passengers[
                      infantPassengerIndex
                    ].isChecked = currentPassenger.isChecked;
                    temp.flight[0].segGroups[1].passengers[
                      infantPassengerIndex
                    ].isDisabled = currentPassenger.isChecked;
                  }
                }
              );
            } else if (selAdtCount === 0) {
              temp.flight[0].segGroups[segGroupIndex].passengers.forEach(
                (passenger, infantPassengerIndex) => {
                  if (
                    temp.flight[0].segGroups.length > 1 &&
                    passenger.paxType === "INF"
                  ) {
                    const infantPassenger =
                      temp.flight[0].segGroups[segGroupIndex].passengers[
                      infantPassengerIndex
                      ];
                    infantPassenger.isChecked = currentPassenger.isChecked;
                    temp.flight[0].segGroups[1].passengers[
                      infantPassengerIndex
                    ].isChecked = currentPassenger.isChecked;
                    temp.flight[0].segGroups[1].passengers[
                      infantPassengerIndex
                    ].isDisabled = currentPassenger.isChecked;
                  }
                }
              );
            }
          }
        }
      } else {
        temp.flight[0].segGroups[index].passengers.map((pax) => {
          if (pax.isCancelled === false) {
            pax.isChecked = value;
            pax.isDisabled = value;
          }
          return pax;
        });
      }
    }
    setCancelDetails({ ...cancelDetails, ...temp });
  };



  const renderItineraryDetails = (segGroup, segGroups, segGroupIndex) => {
    if (
      !segGroup ||
      !segGroup.segments ||
      !segGroup.segments[0] ||
      !segGroup.passengers ||
      !segGroup.passengers[0]
    ) {
      return null;
    }
    const { segments, passengers } = segGroup;


    return (
      <React.Fragment>
        {segments.map((segment, index) => (
          <div
            className="cancelModalItenaryTopContainer fw500"
            key={segment.eqpType}
          >
            <p className="m0">
              <span className="padLR1vw">{`${segment.origin} --> ${segment.destination}`}</span>
              <span className="padLR1vw">
                {moment(new Date(segment.departureOn)).format(
                  "MM-DD-YYYY HH:mm"
                )}
              </span>

              {/* {moment(new Date(segment.departureOn)).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )} */}

              <span className="padLR1vw">{`${segment.mrkAirline} ${segment.flightNum}`}</span>
              <span className="padLR1vw">
                {segment.pnr ? `PNR- ${segment.pnr}` : <div></div>}
              </span>
              {index === 0 ? (
                <span className="floatInEnd">
                  <Checkbox
                    label="Select All"
                    disabled={segGroup.isDisabled}
                    checked={
                      (segGroup.isAllChecked && segGroups[0].isAllChecked) ||
                      segGroup.isAllChecked
                    }
                    onChange={(event) =>
                      handleCheckboxsegmentChange(
                        segGroupIndex,
                        event.target.checked
                      )
                    }
                  />
                </span>
              ) : null}
            </p>
          </div>
        ))}
        <div className="cancelModalItenaryListContainer">
          {passengers.map((passenger, paxIndex) => {
            return (
              <span key={passenger.id}>
                <Checkbox
                  label={`${passenger.firstName} ${passenger.lastName
                    } (${mapPaxType(passenger.paxType)})`}
                  checked={passenger.isChecked}
                  onChange={(event) =>
                    handleCheckboxpassengerChange(
                      segGroupIndex,
                      paxIndex,
                      event.target.checked
                    )
                  }
                  disabled={passenger.isDisabled}
                />
              </span>
            );
          })}
        </div>
      </React.Fragment>
    );
  };
  const mapPaxType = (paxType) => {
    switch (paxType) {
      case "ADT":
        return "Adult";
      case "CHD":
        return "Child";
      case "INF":
        return "Infant";
      default:
        return paxType;
    }
  };
  const goToTicket = (type) => {
    localStorage.setItem("fareType", type);
    sessionStorage.setItem("orderId", encryptData(orderId));
    window.open(`${window.location.origin}/flight/Eticket`, "_blank");
  };
  const goToInvoice = () => {
    sessionStorage.setItem("orderId", encryptData(orderId));
    window.open(`${window.location.origin}/flight/Invoice`, "_blank");
  };

  // const isRefreshEnabled = moment().diff(moment(bookingDetails.createdOn), 'minutes') <= 30;
  // const isEditTimeEnabled = moment().diff(moment(bookingDetails.createdOn), 'minutes') >= 30

  const moment = require('moment');
  const currentUtcTime = moment.utc();
  const createdOnUtc = moment.utc(bookingDetails.createdOn);
  const minutesSinceCreation = currentUtcTime.diff(createdOnUtc, 'minutes');
  const isRefreshEnabled = minutesSinceCreation < 30;
  const isEditTimeEnabled = minutesSinceCreation >= 30;



  const handleRefresh = async () => {
    setShowLoader(true);

    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/CreatePNR/v1/RefreshTktTime/" + orderId.split("GFS")[1],

        // JSON.stringify({
        //   OrderId: orderId.split("GFS")[1],
        // }),
        { headers }
      )
      .then(() => {
        loadBookingDetails();
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });

  };

  const formatDate = (inputDateStr) => {
    const inputDate = new Date(inputDateStr);

    const year = inputDate.getFullYear();
    const month = ('0' + (inputDate.getMonth() + 1)).slice(-2);
    const day = ('0' + inputDate.getDate()).slice(-2);
    const hours = ('0' + inputDate.getHours()).slice(-2);
    const minutes = ('0' + inputDate.getMinutes()).slice(-2);
    const seconds = ('0' + inputDate.getSeconds()).slice(-2);

    const formattedDateForApi = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateForApi;
  };

  const updateNewTkTimeLimit = async () => {
    const formattedTkTimeLimit = formatDate(tkTimeLimit);

    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_CORE_URL + "Flights/Booking/CreatePNR/v1/ManualUpdateTktTime",

        JSON.stringify({
          OrderId: orderId.split("GFS")[1],
          TicketTime: formattedTkTimeLimit
        }),
        { headers }
      )
      .then(() => {
        startTimer(tkTimeLimit)
        loadBookingDetails();
      })
      .catch((err) => {
        console.log(err);
      });

  }




  return (
    <div className="view-booking-container">
      <React.Fragment>
        <Modal
          open={cancelReqFailureDialogOpen}
          onClose={() => setCancelReqFailureDialogOpen(false)}
        >
          <ModalDialog
            variant="outlined"
            role="alertdialog"
            className="textCenter pad1vw"
          >
            <ModalClose variant="plain" sx={{ m: 0 }} />
            <h3 className="colorRed">Oops</h3>
            <span>
              <b>Cancellation Request Failed</b>
            </span>
            <span>Please Try Again OR Contact Customer Care</span>
            {/* <div className="cancelModalFooterContainer textEnd padR2vw">
                
                <Button
                  variant="contained"
                  className="cancel-btn"
                  onClick={(event) => {
                    cancelNow();
                  }}
                >
Close                </Button>
              </div> */}
          </ModalDialog>
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          open={cancelReqSuccessDialogOpen}
          onClose={() => {
            setCancelReqSuccessDialogOpen(false);
            setCancelModalOpen(false);
            loadBookingDetails();
          }}
        >
          <ModalDialog
            variant="outlined"
            role="alertdialog"
            className="textCenter pad1vw"
          >
            <ModalClose variant="plain" sx={{ m: 0 }} />
            <h3 className="colorGreen">Thank You</h3>
            <Divider />
            <DialogContent>
              <span>
                <b>Cancellation Request Created Successfully</b>
              </span>
              <br></br>
              <span>Cancellation RefNo: {cancelRefno}</span>
            </DialogContent>
          </ModalDialog>
        </Modal>
      </React.Fragment>
      <React.Fragment>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          sx={{
            display: "flex",
            top: "5vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              minWidth: "50vw",
              maxWidth: "50vw",
              maxHeight: "35vw",
              overflowY: "scroll",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Cancel Itenary{" "}
            </Typography>
            <div className="cancelModalItenaryParentContainer fs14">
              {cancelDetails &&
                cancelDetails.flight &&
                cancelDetails.flight[0] &&
                cancelDetails.flight[0].segGroups && (
                  <>
                    {cancelDetails.flight[0].segGroups.map(
                      (segGroup, segGroupIndex) => (
                        <React.Fragment key={segGroup.id}>
                          {renderItineraryDetails(
                            segGroup,
                            cancelDetails.flight[0].segGroups,
                            segGroupIndex
                          )}
                          <hr />
                        </React.Fragment>
                      )
                    )}
                  </>
                )}
              <div className="cancelModalFullRefundContainer padLR2vw">
                <Checkbox
                  label="Full Refund(Select If Flight Cancelled)"
                  checked={selectedFullRefund}
                  onChange={() => handleCheckboxfullrefundChange()}
                />{" "}
                <br></br>
                <p className="m0">
                  <span className="colorRed">Note: </span>
                  <span>
                    This Will Generate Offline Request To Cancel The Booking
                  </span>{" "}
                </p>
              </div>
              <div className="cancelModalRemarkContainer  fw500">
                <p className="m0 padLR2vw">
                  <b>Remark</b>
                </p>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    // label="Remarks"
                    multiline
                    maxRows={4}
                    placeholder="Max 300 Characters"
                    sx={{
                      whiteSpace: "pre-wrap",
                      maxWidth: "98.5%",
                      minHeight: "100px !important",
                    }}
                    variant="outlined"
                    value={cancelRemarks}
                    inputProps={{ maxLength: 300 }}
                    onChange={(event) => {
                      setCancelRemarks(() => event?.target?.value);
                    }}
                  />
                </Box>{" "}
              </div>
              <div className="cancelModalFooterContainer textEnd padR2vw">
                <span className="fs10">
                  {" "}
                  I Accept
                  <a
                    className="linkcolor padR5 padL5"
                    href="blank"
                    target="_blank"
                  >
                    Terms And Conditions
                  </a>
                  By Clicking On Cancel Now
                </span>
                {/* {ispassengerChecked === true && ( */}
                <Button
                  variant="contained"
                  className={`cancel-btn ${!isButtonEnable ? "disabled-btn" : ""
                    }`}
                  onClick={cancelNow}
                  disabled={!isButtonEnable}
                >
                  Cancel Now
                </Button>
                {/* )} */}
              </div>
            </div>
          </Sheet>
        </Modal>
      </React.Fragment>
      {/* {showLoader && (
        <div style={{ position: "absolute", left: "50%", top: "20%" }}>
          <CircularProgress />
        </div>
      )} */}
      {showLoader ? (
        <RiseLoading isviewBooking={showLoader} />
      ) : (
        <>
          <Grid container className="viewBooking">
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {bookingDetails &&
                bookingDetails.flights &&
                bookingDetails.flights.length > 0 ? (
                bookingDetails.flights.map((flight) => (
                  <div>

                    <FlightItinerary bookingDetails={bookingDetails} flight={flight} getClientName={getClientName}
                      pccList={pccList} orderId={orderId} countDownText={countDownText} isRefreshEnabled={isRefreshEnabled}
                      isEditTimeEnabled={isEditTimeEnabled} setShowAddTimeLimit={setShowAddTimeLimit} handleRefresh={handleRefresh}
                      handleClickOpenPNRDialog={handleClickOpenPNRDialog} errorMsgTkt={errorMsgTkt} updateProcess={updateProcess}
                      updateCancel={updateCancel} fareChangeCheck={fareChangeCheck} goToTicket={goToTicket} updateCancelInvoice={updateCancelInvoice}
                      IBossInvoiceURL={IBossInvoiceURL} setShowInvoicePopover={setShowInvoicePopover} DownloadInvoiceOpen={DownloadInvoiceOpen}
                      goToInvoice={goToInvoice} showInvoicePopover={showInvoicePopover} setShowTicketPopover={setShowTicketPopover}
                      DownloadTicketOpen={DownloadTicketOpen} showTicketPopover={showTicketPopover}
                    />
                    {
                      checkIfUserStaff() ? (
                        <div className="row">
                          <div style={{ paddingLeft: "0px", width: "80%" }}>
                            <FlightSegmentDetails bookingDetails={bookingDetails} flight={flight} setMaxHeight={setMaxHeight} />
                          </div>
                          <div style={{ paddingRight: "0px", width: "20%" }}>
                            <TablePNR flight={flight} maxHeight={maxHeight} />
                          </div>
                        </div>
                      )
                        :
                        <FlightSegmentDetails bookingDetails={bookingDetails} flight={flight} setMaxHeight={setMaxHeight} />
                    }
                    {
                      flight?.pricedDescription && flight?.pricedDescription?.length > 0 ? (
                        <div className="flight-itenary">
                          <div className="flight-itenary-header">
                            <div className="flight-itenary-title-container">
                              <div>
                                Brand Description
                              </div>

                            </div>
                          </div>
                          <BrandedSingleFareTable result={flight} />
                        </div>
                      ) : ''
                    }

                    <PassengerInfo flight={flight} />
                    {
                      checkIfUserStaff() ? (
                        <SupplierFare flight={flight} fopText={fopText} />
                      ) : ''
                    }{
                      checkIfUserStaff() ? (
                        <div className="row">
                          <div className="col-5">
                            <ROE flight={flight} />
                          </div>
                          <div className="col-7">
                            <ComissionParting flight={flight} />
                          </div>
                        </div>
                      ) : ''
                    }
                    <ClientFare flight={flight} />
                    <Baggage flight={flight} />
                    {
                      checkIfUserStaff() ? (
                        <ComissionAndTaxDetails flight={flight} clientName={bookingDetails?.clientName} clientEmail={bookingDetails?.clientEmail} />
                      ) : ''
                    }

                    {flight?.info && checkIfUserStaff() ? (
                      <div className='flight-itenary'>
                        <div className='flight-itenary-header'>
                          <div className='flight-itenary-title-container'>
                            <div>Remarks</div>
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-details-container" style={{ textAlign: "center" }}>
                            <div className="contact-detail-flex">
                              <div className="contact-detail-item">
                                <div className="contact-detail-text">
                                  <span>
                                    {flight.info.match(/(.*?)%%%/) && flight.info.match(/(.*?)%%%/)?.[1]
                                      ? flight.info.match(/(.*?)%%%/)?.[1].trim()
                                      : flight.info}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {checkIfUserStaff() &&
                      historyDetail &&
                      historyDetail.length > 0 ? (
                      <BookingStatusFlow historyDetail={historyDetail} />
                    ) : (
                      ""
                    )}
                  </div>
                ))
              ) : (
                <>
                  <div className="no-results-container">
                    <div className="no-results-icon">
                      <SearchIcon fontSize="large"></SearchIcon>
                    </div>
                    <div className="no-results-text">No Ticket Found</div>
                  </div>
                </>
              )}
              <ContactDetails bookingDetails={bookingDetails} />
              <div className="more-details">
                <div className="more-details-container">
                  <div className="more-detail-item">
                    <div className="more-detail-item-icon invoice-more-detail-item-icon">
                      <BusinessIcon fontSize="medium"></BusinessIcon>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className="invoice-to-person invoice-to-item">
                          Akbar Offshore{" "}
                        </div>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <div>
                              <div className="invoice-to-add1 invoice-to-item">
                                178, Kavarana Mansion , Dr Baba Saheb Ambedkar
                                Rd
                              </div>
                              <div className="invoice-to-add2 invoice-to-item">
                                Dadar East, Mumbai, Maharashtra 400014
                              </div>
                              <div className="invoice-to-add2 invoice-to-item">
                                info@AkbarOffshore.com
                              </div>
                            </div>
                          </Grid>
                          {/* <Grid item xs={6}>
                    <div className="invoice-to-add1 invoice-to-item">
                    <div className="invoice-to-add1 invoice-to-item">CIN :</div>
                    <div className="invoice-to-add1 invoice-to-item">U63030MH2019PTC323160</div>
                  </div>{" "}
                  <div className="invoice-to-add1 invoice-to-item">
                    <div className="invoice-to-add1 invoice-to-item">GSTIN :</div>
                    <div className="invoice-to-add1 invoice-to-item">27AASCA0975B1ZM</div>
                  </div>{" "}
                  <div className="invoice-to-add1 invoice-to-item">
                    <div className="invoice-to-add1 invoice-to-item">PAN :</div>
                    <div className="invoice-to-add1 invoice-to-item">AASCA0975B</div>
                  </div>
                    </Grid> */}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </>
      )}
      <Dialog className="ticket-timelimit-dialog"
        open={showAddTimeLimit}
        onClose={() => setShowAddTimeLimit(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            setShowAddTimeLimit(false);
          },
        }}
      >

        <DialogContent className="m-2">
          <DialogContentText>
            Ticketing Timelimit
          </DialogContentText>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker label="" format="L HH:mm"
                ampm={false}
                onChange={(newValue) => {
                  setTkTimeLimit(newValue.$d ? newValue.$d : null);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

        </DialogContent>
        <DialogActions>
          <Button className="srn-btn" onClick={() => setShowAddTimeLimit(false)}>Cancel</Button>
          <Button className="srn-btn" type="submit" onClick={updateNewTkTimeLimit}>Update</Button>
        </DialogActions>
      </Dialog>
      {/* Update PNR and Ticket Number Dialog Box */}
      {bookingDetails &&
        bookingDetails?.flights &&
        bookingDetails?.flights[0] &&
        bookingDetails?.flights[0]?.currentStatus !== "Void_Failed" &&
        bookingDetails?.flights[0]?.currentStatus !== "Ticketed" && (
          <Dialog
            open={openPNRDialog}
            onClose={handleClosePNRDialog}
            aria-labelledby="pnr-ticket-update-dialog-title"
            aria-describedby="pnr-ticket-update-dialog-description"
            className="pnr-update-dialog-box"
          >
            <DialogTitle
              id="pnr-ticket-update-dialog-title"
              className="main-heading-cls"
            >
              Update PNR and Ticket Number
            </DialogTitle>
            <ValidatorForm
              onError={(errors) => console.log(errors)}
              onSubmit={handlePNRTicketNumberUpdate}
            >
              <DialogContent className="my-3 py-2">
                <DialogContentText id="pnr-ticket-update-dialog-description">
                  <Row>
                    { }
                    <div className="col-12 mb-3">
                      <div className="row">
                        <div className="col-5 normal-txt-cls">
                          <Autocomplete
                            id="FOP-select"
                            value={status}
                            onChange={(event, newvalue) => {
                              setStatus(newvalue);
                            }}
                            options={options}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                //required
                                id="bookingReffNumber"
                                label="Select Booking Status"
                                variant="outlined"
                                value={status}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <div className="row">
                        <div className="col-5 normal-txt-cls">
                          <TextValidator
                            required={status === "Booking_Rejected"}
                            id="bookingReffNumber"
                            label="Remarks"
                            value={remarks}
                            variant="outlined"
                            size="small"
                            onChange={(event) => {
                              setRemarks(event.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            validators={
                              !status == "Ticketed" ? ["required"] : ""
                            }
                            errorMessages={["This Field is Required."]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <div className="row">
                        <div className="col-5 normal-txt-cls">
                          <TextValidator
                            required={
                              status === "Ticketed" || status == undefined
                            }
                            id="bookingReffNumber"
                            label="PNR/Booking Reference"
                            variant="outlined"
                            size="small"
                            value={updatePNRBookingReference}
                            color="secondary"
                            //disabled={defaultPNRBookingRefStatus}
                            onChange={(event) => {
                              setUpdatePNRBookingReference(event.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            validators={
                              status != "Booking_Rejected" ? ["required"] : ""
                            }
                            errorMessages={["This Field is Required."]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 border-right">
                      <div className="row">
                        <div className="col-12 sub-heading-cls mb-2">
                          Segment Information
                        </div>
                      </div>
                      {updatePNRList && updatePNRList.length > 0
                        ? updatePNRList.map((segment, index) => (
                          <>
                            <div className="row py-1">
                              <div className="col-4 normal-txt-cls align-self-center">
                                {segment.origin} - {segment.destination}
                              </div>
                              <div className="col-8 normal-txt-cls">
                                <TextValidator
                                  required={
                                    status === "Ticketed" ||
                                    status == undefined
                                  }
                                  id="pnrNumber"
                                  label="PNR Number"
                                  variant="outlined"
                                  size="small"
                                  value={segment.pnr}
                                  color="secondary"
                                  onChange={(event) =>
                                    handleUpdatePNRTicketFieldChange(
                                      event.target.value,
                                      "pnr",
                                      index
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  validators={
                                    status != "Booking_Rejected"
                                      ? [
                                        "required",
                                        "matchRegexp:^[a-zA-Z0-9]+$",
                                      ]
                                      : ""
                                  }
                                  errorMessages={[
                                    "required",
                                    "No Special Characters Allowed",
                                  ]}
                                />
                              </div>
                            </div>
                          </>
                        ))
                        : ""}
                    </div>
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-12 sub-heading-cls mb-2">
                          Passenger Information
                        </div>
                      </div>
                      {updateTicketList && updateTicketList.length > 0
                        ? updateTicketList.map((passenger, index) => (
                          <>
                            <div className="row py-1">
                              <div className="col-5 normal-txt-cls align-self-center">
                                {passenger.title} {passenger.firstName}{" "}
                                {passenger.lastName}
                              </div>
                              <div className="col-7">
                                <TextValidator
                                  required={
                                    status === "Ticketed" ||
                                    status == undefined
                                  }
                                  id="ticketNumber"
                                  label="Ticket Number"
                                  variant="outlined"
                                  value={passenger.ticketNumber}
                                  className="normal-txt-cls"
                                  onChange={(event) =>
                                    handleUpdatePNRTicketFieldChange(
                                      event.target.value,
                                      "ticketNumber",
                                      index
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  validators={
                                    status != "Booking_Rejected"
                                      ? ["required"]
                                      : ""
                                  }
                                  errorMessages={["required"]}
                                />
                              </div>
                            </div>
                            <Autocomplete
                              id="FOP-select"
                              value={fop != "" ? fop : null}
                              onChange={(event, newValue) => {
                                setFop(newValue);
                              }}
                              options={fopOptions}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  label="FOP"
                                  className="ticket-config-inputs"
                                  value={fop}
                                  variant="filled"
                                  color="secondary"
                                  size="small"
                                //validators={["required"]}
                                //errorMessages={["required"]}
                                />
                              )}
                            />
                            {fop === "Credit Card" ? (
                              <Autocomplete
                                id="PCC-select"
                                value={
                                  selectedcard == "" ? null : selectedcard
                                }
                                onChange={(event, newValue) => {
                                  if (newValue.cardinfo.isActive) {
                                    setSelectedCard(newValue);
                                  } else {
                                    alert("This Card Is InActive");
                                  }
                                }}
                                options={creditCardList}
                                getOptionLabel={(option) =>
                                  !option.cardinfo
                                    ? option.code + " " + option.cardNumber
                                    : option.cardinfo.code +
                                    " " +
                                    "***" +
                                    option.cardinfo.cardNumber.substr(
                                      option.cardinfo.cardNumber.length - 4
                                    )
                                }
                                disableClearable
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    className="ticket-config-inputs"
                                    label="Credit Cards"
                                    value={
                                      selectedcard.code +
                                      " " +
                                      selectedcard.cardNumber
                                    }
                                    variant="filled"
                                    color="secondary"
                                    size="small"
                                    validators={
                                      status != "Booking_Rejected"
                                        ? ["required"]
                                        : ""
                                    }
                                    errorMessages={["required"]}
                                  />
                                )}
                              />
                            ) : null}
                          </>
                        ))
                        : ""}
                    </div>
                  </Row>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="pnr-dialog-action text-center my-2">
                <div
                  className="col-2 sub-heading-cls mb-2 vb-highlight-txt pt-2"
                  id="errorMsgVB"
                >
                  {errorMsg}
                </div>
                {!updateProcess && (
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                )}
                <Button variant="outlined" onClick={handleClosePNRDialog}>
                  Cancel
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        )}
      {/* Fare change alert Dialog Box */}
      {(
        <Dialog
          open={showFareChange}
          // onClose={setAirportChangeAlertOpen((airportChangeAlertOpen) => false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="airportChangeAlert"
        >
          <DialogTitle className="airportChangeAlert-title textCenter">
            {/* <span>Alert</span> */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className={`${!isRoundtrip
                  ? "airportChangeAlert-top-oneway"
                  : "airportChangeAlert-top-return"
                  }`}
              >
                <div className="airportChangeAlert-left">
                  <svg
                    id="Layer_2"
                    height="50"
                    viewBox="0 0 64 64"
                    width="50"
                    className="airportChangeAlert-right-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 2"
                  >
                    <path
                      d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                      fill="#307d01"
                    />
                    <circle cx="32" cy="24" fill="#fff" r="17" />
                    <path
                      d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                      fill="#307d01"
                    />
                    <path
                      d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                      fill="#307d01"
                    />
                    <path
                      d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                      fill="#307d01"
                    />
                    <path
                      d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                      fill="#fff"
                    />
                  </svg>
                  <p>{origin}</p>
                </div>
                <div className="airportChangeAlert-right">
                  <span>
                    <svg
                      id="Layer_2"
                      height="50"
                      viewBox="0 0 64 64"
                      width="50"
                      className="airportChangeAlert-right-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 2"
                    >
                      <path
                        d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                        fill="#307d01"
                      />
                      <circle cx="32" cy="24" fill="#fff" r="17" />
                      <path
                        d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                        fill="#307d01"
                      />
                      <path
                        d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                        fill="#b1eafc"
                      />

                      <path
                        d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                        fill="#307d01"
                      />
                      <path
                        d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                        fill="#307d01"
                      />
                      <path
                        d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                  <p>{destination}</p>
                </div>
              </div>
              <div className="" style={{ maxWidth: '500px' }}>
                {
                  <p className="m0" style={{ color: "#212529" }}>
                    We regret on a change in the fare for your selected flight. You may proceed with the new fare or go back to the results page and select another flight.
                    <span className="eqptype"></span>
                  </p>
                }

                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-5">
                    <div>Old Fare</div>
                    <div className="textCenter">{oldFare || "0.0"}</div>
                  </div>
                  <div className="ms-5">
                    <div>New Fare</div>
                    <div className="textCenter">{newFare || "0.0"}</div>
                  </div>
                </div>

                <p className="m0 textCenter" style={{ color: "#212529" }}>
                  Please verify the Fare Rule before proceeding
                </p>
                <p className="text-dark textCenter text-bold" style={{ cursor: "pointer" }} onClick={() => handleFareRuleClick()}>Fare Rule</p>


                {showFareRule &&
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                  >
                    <Tabs
                      value={miniValue}

                      textColor="primary"
                      indicatorColor="primary"
                      onChange={handleChange}
                      variant="fullWidth"
                    >
                      {bookingDetails &&
                        bookingDetails?.flights &&
                        bookingDetails?.flights[0] &&
                        bookingDetails?.flights[0]?.miniRules && (
                          <Tab
                            className="review-itenary-container-header-details"
                            value="Cancellation"
                            label="Cancellation"
                          />
                        )}
                      {bookingDetails &&
                        bookingDetails?.flights &&
                        bookingDetails?.flights[0] &&
                        bookingDetails?.flights[0]?.miniRules && (
                          <Tab
                            className="review-itenary-container-header-details"
                            value="Date Change"
                            label="Date Change"
                          />
                        )}
                    </Tabs>
                  </Box>}

                {(showFareRule && miniValue === "Cancellation") ? (
                  <>
                    {bookingDetails &&
                      bookingDetails?.flights &&
                      bookingDetails?.flights[0] &&
                      bookingDetails?.flights[0]?.miniRules
                      &&
                      getMiniRulesData(
                        bookingDetails?.flights[0]?.miniRules,
                        "Cancellation"
                      ).length === 0 ? (
                      <div className="">
                        <Table className=" fareDetailsTable mb-3">
                          <TableRow>
                            <TableCell
                              align="center"
                              className=""
                            ></TableCell>
                            {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                              <TableCell align="center" className="right">
                                ADT
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.childCount >= 1 ? (
                              <TableCell align="center" className="right">
                                CHD
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                              <TableCell align="center" className="right">
                                INF
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="center"
                              className=""
                              width="175"
                            >
                              Before Trip
                            </TableCell>
                            {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.childCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="center"
                              className=""
                              width="175"
                            >
                              After Trip
                            </TableCell>
                            {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.childCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                              <TableCell align="center" className="right">
                                Non Refundable
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        </Table>
                        <Row className="mx-0 pb-2">
                          <div className="col-12 flt-highlight-label px-0 py-1">
                            <span className="badge flt-imp-badge-label mx-2">
                              Important Note
                            </span>
                            <ul className="mt-2">
                              <li className="flt-imp-msg">
                                Additional admin fee applicable.
                              </li>
                              <li className="flt-imp-msg">
                                Please contact the operations team for further
                                assistance and clarity on this if required.
                              </li>
                              <li className="flt-imp-msg">
                                ROE (Rate of Exchange) is always based on the
                                current rates.{" "}
                              </li>
                            </ul>
                          </div>
                        </Row>
                      </div>
                    ) : (
                      <div className="cancellationDisplay">
                        {bookingDetails?.flights[0]?.adultCount >= 1 ||
                          bookingDetails?.flights[0]?.childCount >= 1 ||
                          bookingDetails?.flights[0]?.infantCount >= 1 ? (
                          <div>
                            {bookingDetails?.flights[0]?.adultCount >= 1 && (
                              <>
                                {" "}
                                <div className="">
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            xs="auto"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            xs="auto"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            xs="auto"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                            xs={5}
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterAdultCancellation
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterAdultCancellation)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow
                                                  key={"ADT" + index}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <>
                                                    {index === 0 ? (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterAdultCancellation
                                                          ).length
                                                        }
                                                      >
                                                        ADT
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <TableCell
                                                      className=""
                                                      align="center"
                                                      xs="auto"
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.adultCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        xs="auto"
                                                      >
                                                        {listItem?.canAmt !==
                                                          undefined &&
                                                          listItem?.canAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.canAmt
                                                          : listItem?.canAmt ===
                                                            undefined ||
                                                            listItem?.canAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            " 0"
                                                            : currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.adultCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                        xs="5"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "-"
                                                            : "-"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            {bookingDetails?.flights[0]?.childCount >= 1 && (
                              <>
                                <div className="">
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterChildCancellation
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterChildCancellation)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow>
                                                  <>
                                                    {index === 0 ? (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterChildCancellation
                                                          ).length
                                                        }
                                                      >
                                                        CHD
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <TableCell
                                                      align="center"
                                                      className=""
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.childCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                      >
                                                        {listItem?.canAmt !==
                                                          undefined &&
                                                          listItem?.canAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.canAmt
                                                          : listItem?.canAmt ===
                                                            undefined ||
                                                            listItem?.canAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            " 0"
                                                            : currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.childCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "-"
                                                            : "-"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            {bookingDetails?.flights[0]?.infantCount >= 1 && (
                              <>
                                <div className="">
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        {" "}
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterInfantCancellation
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterInfantCancellation)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow>
                                                  <>
                                                    {index === 0 ? (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterInfantCancellation
                                                          ).length
                                                        }
                                                      >
                                                        INF
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <TableCell
                                                      align="center"
                                                      className=""
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.infantCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                      >
                                                        {listItem?.canAmt !==
                                                          undefined &&
                                                          listItem?.canAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.canAmt
                                                          : listItem?.canAmt ===
                                                            undefined ||
                                                            listItem?.canAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            " 0"
                                                            : currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.infantCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "Nil"
                                                            : "Nil"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            <Row className="mx-0 pb-2">
                              <div className="col-12 flt-highlight-label px-0 py-1">
                                <span className="badge flt-imp-badge-label mx-2">
                                  Important Note
                                </span>
                                <ul className="mt-2">
                                  <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                  </li>
                                  <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this if
                                    required.
                                  </li>
                                  <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based on
                                    the current rates.{" "}
                                  </li>
                                </ul>
                              </div>
                            </Row>
                          </div>
                        ) : (
                          <div className="">
                            <TableContainer component={Paper}>
                              <Table className=" fareDetailsTable mb-3">
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                  ></TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      ADT
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      CHD
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      INF
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                    width="175"
                                  >
                                    Before Trip
                                  </TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                    width="175"
                                  >
                                    After Trip
                                  </TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                              </Table>
                              <Row className="mx-0 pb-2">
                                <div className="col-12 flt-highlight-label px-0 py-1">
                                  <span className="badge flt-imp-badge-label mx-2">
                                    Important Note
                                  </span>
                                  <ul className="mt-2">
                                    <li className="flt-imp-msg">
                                      Additional admin fee applicable.
                                    </li>
                                    <li className="flt-imp-msg">
                                      Please contact the operations team for
                                      further assistance and clarity on this
                                      if required.
                                    </li>
                                    <li className="flt-imp-msg">
                                      ROE (Rate of Exchange) is always based
                                      on the current rates.{" "}
                                    </li>
                                  </ul>
                                </div>
                              </Row>
                            </TableContainer>
                          </div>
                        )}
                      </div>
                    )
                    }
                  </>
                ) : (
                  ""
                )}
                {(showFareRule && miniValue === "Date Change") ? (
                  <>
                    {bookingDetails?.flights[0]?.miniRules &&
                      getMiniRulesData(
                        bookingDetails?.flights[0]?.miniRules,
                        "Date Change"
                      ) &&
                      getMiniRulesData(
                        bookingDetails?.flights[0]?.miniRules,
                        "Date Change"
                      ).length === 0 ? (
                      <div className="">
                        {" "}
                        <TableContainer component={Paper}>
                          <Table className=" fareDetailsTable mb-3">
                            <TableRow>
                              <TableCell
                                align="center"
                                className=""
                              ></TableCell>
                              {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  ADT
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  CHD
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  INF
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell
                                align="center"
                                className=""
                                width="175"
                              >
                                Before Trip
                              </TableCell>
                              {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell
                                align="center"
                                className=""
                                width="175"
                              >
                                After Trip
                              </TableCell>
                              {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                              {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                <TableCell align="center" className="right">
                                  Non Refundable
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          </Table>
                          <Row className="mx-0 pb-2">
                            <div className="col-12 flt-highlight-label px-0 py-1">
                              <span className="badge flt-imp-badge-label mx-2">
                                Important Note
                              </span>
                              <ul className="mt-2">
                                <li className="flt-imp-msg">
                                  Additional admin fee applicable.
                                </li>
                                <li className="flt-imp-msg">
                                  Please contact the operations team for
                                  further assistance and clarity on this if
                                  required.
                                </li>
                                <li className="flt-imp-msg">
                                  ROE (Rate of Exchange) is always based on
                                  the current rates.{" "}
                                </li>
                              </ul>
                            </div>
                          </Row>{" "}
                        </TableContainer>
                      </div>
                    ) : (
                      <div className="dateChangeDisplay">
                        {bookingDetails?.flights[0]?.adultCount >= 1 ||
                          bookingDetails?.flights[0]?.childCount >= 1 ||
                          bookingDetails?.flights[0]?.infantCount >= 1 ? (
                          <div>
                            {bookingDetails?.flights[0]?.adultCount >= 1 && (
                              <>
                                <div className="">
                                  {" "}
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterAdultChangeDate
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterAdultChangeDate)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow>
                                                  <>
                                                    {index === 0 && (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterAdultChangeDate
                                                          ).length
                                                        }
                                                      >
                                                        ADT
                                                      </TableCell>
                                                    )}
                                                    <TableCell
                                                      align="center"
                                                      className=""
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.adultCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                      >
                                                        {listItem?.exgAmt !==
                                                          undefined &&
                                                          listItem?.exgAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.exgAmt
                                                          : listItem?.exgAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            "  " +
                                                            0
                                                            : listItem?.exgAmt ===
                                                            undefined &&
                                                            currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.adultCount >=
                                                      1 ? (
                                                      <TableCell
                                                        className="right"
                                                        align="center"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "-"
                                                            : "-"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>{" "}
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            {bookingDetails?.flights[0]?.childCount >= 1 && (
                              <>
                                <div className="">
                                  {" "}
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        {" "}
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterChildChangeDate
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterChildChangeDate)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow>
                                                  <>
                                                    {index === 0 ? (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterChildChangeDate
                                                          ).length
                                                        }
                                                      >
                                                        CHD
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <TableCell
                                                      align="center"
                                                      className=""
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.childCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                      >
                                                        {listItem?.exgAmt !==
                                                          undefined &&
                                                          listItem?.exgAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.exgAmt
                                                          : listItem?.exgAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            "  " +
                                                            0
                                                            : listItem?.exgAmt ===
                                                            undefined &&
                                                            currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.childCount >=
                                                      1 ? (
                                                      <TableCell
                                                        className="right"
                                                        align="center"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "Nil"
                                                            : "Nil"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>{" "}
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            {bookingDetails?.flights[0]?.infantCount >= 1 && (
                              <>
                                <div className="">
                                  {" "}
                                  <TableContainer component={Paper}>
                                    <Table className=" fareDetailsTable mb-3">
                                      <TableHead>
                                        <TableRow className="tableHead">
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Pax
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Applicable For
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            className=""
                                          >
                                            Remarks
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {bookingDetails?.flights[0]?.miniRules &&
                                          bookingDetails?.flights[0]?.miniRules.filter(
                                            filterInfantChangeDate
                                          ).length > 0 &&
                                          bookingDetails?.flights[0]?.miniRules
                                            .filter(filterInfantChangeDate)
                                            .map((listItem, index) =>
                                              listItem.apply ? (
                                                <TableRow>
                                                  <>
                                                    {index === 0 ? (
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        xs="auto"
                                                        align="center"
                                                        className="tableHead"
                                                        rowSpan={
                                                          bookingDetails?.flights[0]?.miniRules &&
                                                          bookingDetails?.flights[0]?.miniRules.filter(
                                                            filterInfantChangeDate
                                                          ).length
                                                        }
                                                      >
                                                        INF
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <TableCell
                                                      align="center"
                                                      className=""
                                                    >
                                                      {listItem.apply}
                                                    </TableCell>
                                                    {bookingDetails?.flights[0]?.infantCount >=
                                                      1 ? (
                                                      <TableCell
                                                        align="center"
                                                        className="right"
                                                      >
                                                        {listItem?.exgAmt !==
                                                          undefined &&
                                                          listItem?.exgAmt !== 0
                                                          ? currentClientCurrencyCode +
                                                          "  " +
                                                          listItem?.exgAmt
                                                          : listItem?.exgAmt ===
                                                            0
                                                            ? currentClientCurrencyCode +
                                                            "  " +
                                                            0
                                                            : listItem?.exgAmt ===
                                                            undefined &&
                                                            currentClientCurrencyCode +
                                                            " 0"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {bookingDetails?.flights[0]?.infantCount >=
                                                      1 ? (
                                                      <TableCell
                                                        className="right"
                                                        align="center"
                                                        width="40%"
                                                      >
                                                        {listItem?.remarks !=
                                                          undefined &&
                                                          listItem?.remarks !=
                                                          ""
                                                          ? listItem?.remarks
                                                          : listItem?.remarks !=
                                                            undefined &&
                                                            listItem?.remarks ==
                                                            ""
                                                            ? "Nil"
                                                            : "Nil"}
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                </TableRow>
                                              ) : (
                                                ""
                                              )
                                            )}
                                      </TableBody>
                                    </Table>{" "}
                                  </TableContainer>
                                </div>
                              </>
                            )}
                            <Row className="mx-0 pb-2">
                              <div className="col-12 flt-highlight-label px-0 py-1">
                                <span className="badge flt-imp-badge-label mx-2">
                                  Important Note
                                </span>
                                <ul className="mt-2">
                                  <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                  </li>
                                  <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this if
                                    required.
                                  </li>
                                  <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based on
                                    the current rates.{" "}
                                  </li>
                                </ul>
                              </div>
                            </Row>
                          </div>
                        ) : (
                          <div className="">
                            {" "}
                            <TableContainer component={Paper}>
                              <Table className=" fareDetailsTable mb-3">
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                  ></TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      ADT
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      CHD
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      INF
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                    width="175"
                                  >
                                    Before Trip
                                  </TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className=""
                                    width="175"
                                  >
                                    After Trip
                                  </TableCell>
                                  {bookingDetails?.flights[0]?.adultCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.childCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {bookingDetails?.flights[0]?.infantCount >= 1 ? (
                                    <TableCell
                                      align="center"
                                      className="right"
                                    >
                                      Non Refundable
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                </TableRow>
                              </Table>
                            </TableContainer>
                            <Row className="mx-0 pb-2">
                              <div className="col-12 flt-highlight-label px-0 py-1">
                                <span className="badge flt-imp-badge-label mx-2">
                                  Important Note
                                </span>
                                <ul className="mt-2">
                                  <li className="flt-imp-msg">
                                    Additional admin fee applicable.
                                  </li>
                                  <li className="flt-imp-msg">
                                    Please contact the operations team for
                                    further assistance and clarity on this if
                                    required.
                                  </li>
                                  <li className="flt-imp-msg">
                                    ROE (Rate of Exchange) is always based on
                                    the current rates.{" "}
                                  </li>
                                </ul>
                              </div>
                            </Row>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}






              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="airportChangeAlert-footer">
            <Button
              className="srn-btn1"
              variant="contained"
              onClick={() => setShowFareChange(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateTicket(bookingDetails);
                setShowFareChange(false);
              }}
              autoFocus
              variant="contained"
              className="srn-btn marginBR5"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
