import React, { useState, useEffect } from "react";
import "./Supplier.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import AddSupplierCard from "./AddSupplierCard/AddSupplierCard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import SupplierTable from "./SupplierTable/SupplierTable";
import SupplierDrawer from "./SupplierDrawer/SupplierDrawer";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Supplier() {
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [pccDetails, setPCCDetails] = useState([]);
  const [pccCode, setPCCCode] = useState("");
  const [iPcc, setIPcc] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [vendorList, setVendor] = useState([]);
  const [clientSelect, setClientSelect] = useState([]);
  const [productType, setProductType] = useState("Flight");

  const history = useHistory();
  useEffect(() => {
    if (isUserHasPermission("PCCRead")) {
      loadPCCs();
      loadVendor();
      loadClient();
    } else {
      history.push("/Unauthorized");
    }
  }, []);

  useEffect(() => {
    if (isUserHasPermission("PCCRead")) {
      if (productType === 'Flight') {
        loadPCCs("api/Configuration/PccConfig");
      } else if (productType === 'Rail') {
        loadPCCs("api/Configuration/RailPcc");
      }
    }
  }, [productType]);


  async function loadPCCs(url) {
    setPCCDetails([]);

    let filter = "";

    if (pccCode !== "") filter = "pccCode==" + pccCode;

    if (iPcc !== "") {
      if (filter === "") filter = "iPcc==" + iPcc;
      else filter = filter + "iPcc==" + iPcc;
    }
    if (supplierName !== "") {
      if (filter === "") filter = "supplierName==" + supplierName;
      else filter = filter + "supplierName==" + supplierName;
    }
    if (filter !== "") filter = "?filters=" + filter;

    await axios
      .get(
        process.env.REACT_APP_CORE_URL + url + filter
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.pccConfigs.isActive) {
            item.pccConfigs.status = 'active'
          } else {
            item.pccConfigs.status = 'inactive'
          }
        })
        setPCCDetails(response.data);
      })
      .catch((error) => console.log(error));
  }
  async function loadVendor() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        let tmpArray = [];
        for (var i = 0; i < response.data.length; i++) {
          tmpArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        setVendor(tmpArray);
      })
      .catch((error) => console.log(error));
  }

  async function loadClient() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
      .then((response) => {
        let result = response.data;
        // console.log(result);
        const ClientNames = result.map((sp) => ({
          id: sp.clientpforile.id,
          name: sp.clientpforile.clientName,
        }));
        // console.log("Fetched Client Names:", ClientNames);
        setClientSelect(ClientNames);
      })
      .catch((error) => console.log(error));
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const productChangeHandler = (e) => {
    if (e.target.value === 'flight') {
      setProductType('Flight');
    } else if (e.target.value === 'rail') {
      setProductType('Rail');
    }
  }

  const toggleDrawer = (value) => (event) => {

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(() => value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    let url = "api/Configuration/PccConfig";
    if (productType === 'Rail') {
      url = "api/Configuration/RailPcc";
    }
    setDrawer(false);
    setSnackbarOpen(true);
    loadPCCs(url);
  };
  useEffect(() => {
    if (drawer === false) {
      setSelectedItem(() => { });
    }
  }, [drawer])
  useEffect(() => {
  }, [selectedItem])
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="supplier-container">
      <div className="supplier-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-supplier-container">
              <div className="supplier-table-container">
                <div className="supplier-title-add-button-container">
                  <div className="supplier-heading">PCC Details</div>
                  <div className="search-icon-button-container">
                    {/* <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon> */}
                    <div
                      className="add-supplier-button mt-0px pointer"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New PCC
                    </div>
                  </div>
                </div>

                <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1 align-center mb-3">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-labelledby="position"
                      defaultValue="flight"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value='flight' name="flight" control={<Radio />} label="Flight" onChange={productChangeHandler} checked={productType === 'Flight'} />
                      <FormControlLabel value='rail' name="rail" control={<Radio />} label="Rail" onChange={productChangeHandler} checked={productType === 'Rail'} />
                    </RadioGroup>
                  </FormControl>
                </div>

                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="supplier-card-container">
                    <AddSupplierCard
                      setPCCCode={setPCCCode}
                      setIPcc={setIPcc}
                      setSupplierName={setSupplierName}
                      loadPCCs={loadPCCs}
                    ></AddSupplierCard>
                  </div>
                </Collapse>
                <SupplierTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  pccDetails={pccDetails}
                ></SupplierTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <SupplierDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            vendorList={vendorList}
            clientSelect={clientSelect}
            productType={productType}
          ></SupplierDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
